
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  setup() {
    onMounted(() => {
      if (route.params.id) {
        setCurrentPageBreadcrumbs("抽赏记录", ["套系管理;box_infos", route.params.box_info_id.toString(), `箱子列表;boxes;${route.params.box_info_id.toString()}`, route.params.id.toString()])
      } else {
        setCurrentPageBreadcrumbs('抽赏记录管理', ['抽赏记录管理'])
      }
    })

    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const tableData = ref([])

    const formData = ref({
      name: "",
      contact_mobile: "",
      enabled: "",
      mobile: '',
    });

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0,
    });

    let date = new Date()
    let from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
    let to = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)

    const duration = ref([
      from,
      to,
    ])

    const query = () => {
      if (route.params.id) {
        store
          .dispatch(Actions.GET_BOX_RECORDS, {
            box_info_id: route.params.box_info_id,
            box_id: route.params.id,
            from: `${duration.value[0].toLocaleDateString()} ${duration.value[0].getHours()}:${duration.value[0].getMinutes()}:${duration.value[0].getSeconds()}`,
            to: `${duration.value[1].toLocaleDateString()} ${duration.value[1].getHours()}:${duration.value[1].getMinutes()}:${duration.value[1].getSeconds()}`,
            ...formData.value,
            ...page.value
          })
          .then(() => {
            tableData.value = store.getters.currentBoxRecords
            page.value.totalResult = store.getters.currentBoxRecordCount
          })
          .catch(() => {
            // const [error] = Object.keys(store.getters.getErrors);
            // Swal.fire({
            //   text: store.getters.getErrors[error],
            //   icon: "error",
            //   buttonsStyling: false,
            //   confirmButtonText: "Try again!",
            //   customClass: {
            //     confirmButton: "btn fw-bold btn-light-danger",
            //   },
            // });
          })
      } else {
        store
          .dispatch(Actions.GET_BOX_RECORDS, {
            from: `${duration.value[0].toLocaleDateString()} ${duration.value[0].getHours()}:${duration.value[0].getMinutes()}:${duration.value[0].getSeconds()}`,
            to: `${duration.value[1].toLocaleDateString()} ${duration.value[1].getHours()}:${duration.value[1].getMinutes()}:${duration.value[1].getSeconds()}`,
            ...formData.value,
            ...page.value
          })
          .then(() => {
            tableData.value = store.getters.currentBoxRecords
            page.value.totalResult = store.getters.currentBoxRecordCount
          })
          .catch(() => {
            // const [error] = Object.keys(store.getters.getErrors);
            // Swal.fire({
            //   text: store.getters.getErrors[error],
            //   icon: "error",
            //   buttonsStyling: false,
            //   confirmButtonText: "Try again!",
            //   customClass: {
            //     confirmButton: "btn fw-bold btn-light-danger",
            //   },
            // });
          })
      }
    };

    query()

    const submitEvent = () => {
      query()
    }

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage
      query()
    }

    const onCancel = () => {
      formData.value.name = ''
    }

    return {
      tableData,
      formData,
      submitEvent,
      page,
      onPageChange,
      onCancel,
      duration
    }
  },
})
